/* Tooltip
 * 
 * anchor: the element the tooltip points to
 * element: (optional) the tooltip element
 * trigger: (defaults to anchor) the element that shows/hides the tooltip
 * content: (optional) the text of the tooltip
 * level: (optional) Adds an alert level/color class (success, warn, alert)
 */

// TODO: simplify arugments, perhaps with an options object
function Tooltip(anchor, element, trigger, content, level) {
  if (!anchor) throw new Error('Tooltip requires an HTML element for \'anchor\'.');
  this.init(anchor, element, trigger, content, level);
}

Tooltip.prototype.init = function(anchor, element, trigger, content, level) {
  this.anchor = anchor;
  this.trigger = trigger || anchor;
  
  if (!element) {
    element = this.createHTML(content);
    $(element).insertAfter(anchor);
  }
  else {
    element = $(element)[0];
  }

  element.tooltip = this;
  this.element = element;
  this.pointer = $(element).find('.tooltip__pointer')[0];
  $(element).on('click', function() {
    this.tooltip.close();
  });

  this.trigger.tooltip = this;
  $(this.trigger).on('click', function() {
    this.tooltip.toggle();
  });

  if (level) this.setLevel(level);
};

Tooltip.prototype.createHTML = function(content) {
  var element = $('<div/>').addClass('pl-tooltip').html(content)[0];
  $('<div/>').addClass('pl-tooltip__pointer').prependTo(element);
  return element;
};

Tooltip.prototype.open = function() {
  $(this.element).addClass('pl-is-visible');
  this.center();
};

Tooltip.prototype.close = function() {
  $(this.element).removeClass('pl-is-visible');
};

// Open element if closed, close if opened
Tooltip.prototype.toggle = function() {
  if ($(this.element).hasClass('pl-is-visible')) this.close();
  else this.open();
};

// Position tooltip element centered relative to the anchor
// (assumes same offset parent)
Tooltip.prototype.center = function() {
  var left = $(this.anchor).outerWidth()/2 + $(this.anchor).position().left - $(this.element).outerWidth()/2;
  $(this.element).css('left',left);
};

// Change the class that sets the level (color) of the tooltip
Tooltip.prototype.setLevel = function(level) {
  if (!Tooltip.levels[level]) return false;
  // TODO: abstract the process of getting values out of an object
  var classes = [];
  for (var key in Tooltip.levels) {
    if (Tooltip.levels.hasOwnProperty(key)) {
      classes.push(Tooltip.levels[key]);
    }
  }
  $(this.element).removeClass(classes.join(' ')).addClass(Tooltip.levels[level]);
};


// # Tooltip properties / constants

// Only allow setLevel() to use these classes
Tooltip.levels = {
  default: 'pl-tooltip',
  success: 'pl-tooltip--success',
  warn: 'pl-tooltip--warn',
  alert: 'pl-tooltip--alert'
};

// Reusable selector for finding tooltip elements
Tooltip.selector = '.pl-tooltip, [class*="pl-tooltip--"]';
